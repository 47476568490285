import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import Icon from "@ailibs/feather-react-ts";
const getRandomNumber = () => {
  return Math.floor(Math.random() * 100);
};

const FooterNavigation = () => {
  return (
    <Row className="bg-light py-2 text-center d-md-none fixed-bottom">
      <Row className="bottom-bar px-5">
        <Col>
          <Row className="d-flex align-items-center">
            <Col>
              <Row className="d-flex align-items-center">
                <Link to="/home" className="bottom-bar-item">
                  <Icon name="home" />
                </Link>
              </Row>
              <Row className="d-flex align-items-center">
                <span>Home</span>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row className="d-flex align-items-center">
            <Link to="/maphias" className="bottom-bar-item">
              <Icon name="grid" />
            </Link>
          </Row>
          <Row className="d-flex align-items-center">
            <span>Maphias</span>
          </Row>
        </Col>
        <Col>
          <Row className="d-flex align-items-center">
            <Link to="/invite" className="bottom-bar-item">
              <Icon name="user-plus" />
            </Link>
          </Row>
          <Row className="d-flex align-items-center">
            <span>Invite</span>
          </Row>
        </Col>
        <Col>
          <Row className="d-flex align-items-center">
            <Link to="/explore" className="bottom-bar-item">
              <Icon name="compass" />
            </Link>
          </Row>
          <Row className="d-flex align-items-center">
            <span>Explore</span>
          </Row>
        </Col>
      </Row>
    </Row>
  );
};

const FooterInfo = () => {
  return (
    <Row className="py-3 d-none d-md-flex">
      <Col xs={12} md={6} className="">
        {new Date().getFullYear()} © <span className="maphia-text">Maphia</span>{" "}
        All rights reserved.
      </Col>
      <Col xs={12} md={6}>
        <div className="text-sm-end">
          <Link
            to={`/privacy-policy?r=${getRandomNumber()}`}
            className="footer-link mx-2"
          >
            Privacy Policy
          </Link>
          <Link to="/investors" className="footer-link mx-2">
            Investors
          </Link>
          <Link to="/careers" className="footer-link mx-2">
            Careers
          </Link>
        </div>
      </Col>
    </Row>
  );
};

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <FooterNavigation />
          <FooterInfo />
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
