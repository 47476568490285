import axios from "axios";
import {
  onGlobalError,
  onGlobalSuccess,
  API_HOST_PREFIX,
} from "./serviceHelpers";

const api = `${API_HOST_PREFIX}/users`;

/**
 *
 * @param {{name: string,
 * email: string,
 * password: string,
 * passwordConfirm: string}} payload
 */
export const register = async (payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    url: `${api}/register`,
    data: payload,
  };
  try {
    const response = await axios(config);
    return onGlobalSuccess(response);
  } catch (error) {
    return onGlobalError(error);
  }
};

/**
 *
 * @param {{
 * email: string,
 * password: string}} payload
 */
export const login = async (payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    url: `${api}/login`,
    data: payload,
  };
  try {
    const response = await axios(config);
    return onGlobalSuccess(response);
  } catch (error) {
    return onGlobalError(error);
  }
};

export const getCurrent = async (payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    method: "GET",
    url: `${api}/current`,
    data: payload,
  };
  try {
    const response = await axios(config);
    return onGlobalSuccess(response);
  } catch (error) {
    return onGlobalError(error);
  }
};

export const logout = async () => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    method: "GET",
    url: `${api}/signout`,
  };
  try {
    const response = await axios(config);
    return onGlobalSuccess(response);
  } catch (error) {
    return onGlobalError(error);
  }
};

/**
 *
 * @param {string} id
 * @returns
 */
export const getUserById = async (id) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    method: "GET",
    url: `${api}/id/${id}`,
  };
  try {
    const response = await axios(config);
    return onGlobalSuccess(response);
  } catch (error) {
    return onGlobalError(error);
  }
};

/**
 * @param {file} file
 */
export const uploadProfileImage = async (file) => {
  const formData = new FormData();
  formData.append("file", file);
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    method: "POST",
    url: `${api}/profile/picture`,
    data: formData,
  };
  try {
    const response = await axios(config);
    return onGlobalSuccess(response);
  } catch (error) {
    return onGlobalError(error);
  }
};

/**
 *
 * @param {{
 * email: string,
 * password: string}} payload
 */
export const changePassword = async (payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    method: "PUT",
    url: `${api}/change-password`,
    data: payload,
  };
  try {
    const response = await axios(config);
    return onGlobalSuccess(response);
  } catch (error) {
    return onGlobalError(error);
  }
};

/**
 *
 * @param {email} email
 */
export const requestPasswordReset = async (email) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    url: `${api}/forgot-password`,
    data: email,
  };
  try {
    const response = await axios(config);
    return onGlobalSuccess(response);
  } catch (error) {
    return onGlobalError(error);
  }
};

/**
 *
 * @param {{
 * password: string
 * passwordConfirm: string,}} payload
 */
export const resetPassword = async (payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    method: "PUT",
    url: `${api}/reset-password`,
    data: payload,
  };
  try {
    const response = await axios(config);
    return onGlobalSuccess(response);
  } catch (error) {
    return onGlobalError(error);
  }
};

/**
 * @param {string} token
 */
export const confirmEmail = async (token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    method: "GET",
    url: `${api}/35e472ae-3239-11ee-be56-0242ac120002/confirm?token=${token}`,
  };
  try {
    const response = await axios(config);
    return onGlobalSuccess(response);
  } catch (error) {
    return onGlobalError(error);
  }
};

/**
 * @param {string} userId
 */
export const reportUser = async (userId) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    url: `${api}/report/${userId}`,
  };
  try {
    const response = await axios(config);
    return onGlobalSuccess(response);
  } catch (error) {
    return onGlobalError(error);
  }
};
