import React from "react";
// import withRouter
import withRouter from "../common/withRouter";
import { childrenProps } from "../../constants/propTypes";
const NonAuthLayout = ({ children }) => {
  return <React.Fragment>{children}</React.Fragment>;
};

NonAuthLayout.propTypes = {
  children: childrenProps,
};

export default withRouter(NonAuthLayout);
