import PropTypes from "prop-types";
import { withTranslation as translateLanguage } from "react-i18next";

export const currentUserPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
});

export const childrenProps = PropTypes.node.isRequired;

export const translate = PropTypes.func.isRequired;

export const withTranslation = translateLanguage;
