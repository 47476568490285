export const layoutTypes = {
  HORIZONTAL: "horizontal",
  VERTICAL: "vertical",
};

export const layoutTheme = {
  LIGHTMODE: "light",
  DARKMODE: "dark",
};

export const layoutWidthTypes = {
  FLUID: "fluid",
  BOXED: "boxed",
};

export const layoutPositions = {
  SCROLLABLE_TRUE: "true",
  SCROLLABLE_FALSE: "false",
};

export const topBarThemeTypes = {
  LIGHT: "light",
  DARK: "dark",
};

export const leftSidebarTypes = {
  DEFAULT: "lg",
  COMPACT: "md",
  ICON: "sm",
};

export const leftSideBarThemeTypes = {
  LIGHT: "light",
  COLORED: "brand",
  DARK: "dark",
};
