const sentryConfig = {
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENV,
  tracesSampleRate: parseFloat(process.env.REACT_APP_SENTRY_SAMPLE_RATE) || 1.0,
  tracePropagationTargets: process.env
    .REACT_APP_SENTRY_TRACE_PROPAGATION_TARGETS
    ? process.env.REACT_APP_SENTRY_TRACE_PROPAGATION_TARGETS.split(",")
    : [],
  replaysSessionSampleRate:
    parseFloat(process.env.REACT_APP_SENTRY_REPLAYS_SESSION_SAMPLE_RATE) || 0.1,
  replaysOnErrorSampleRate:
    parseFloat(process.env.REACT_APP_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE) ||
    1.0,
  feedback: {
    colorScheme: process.env.REACT_APP_SENTRY_FEEDBACK_COLOR_SCHEME || "system",
    isNameRequired:
      process.env.REACT_APP_SENTRY_FEEDBACK_NAME_REQUIRED === "true",
  },
  replay: {
    blockAllMedia:
      process.env.REACT_APP_SENTRY_REPLAY_BLOCK_ALL_MEDIA === "true",
  },
};

export default sentryConfig;
