import React, { useEffect } from "react";
import Header from "./Header";

import Footer from "./Footer";
import {
  childrenProps,
  currentUserPropType,
} from "../../../constants/propTypes";

const NoSideBarLayout = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header />
        <div className="main-content">
          {props.children}
          <Footer />
        </div>
      </div>
    </React.Fragment>
  );
};

NoSideBarLayout.propTypes = {
  children: childrenProps,
  currentUser: currentUserPropType,
};
export default NoSideBarLayout;
