import { AppContextProvider } from "./contexts/GlobalAppContext";

import App from "./App";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import * as Sentry from "@sentry/react";
import sentryConfig from "constants/sentryConfig";
import { getUserTimeZone } from "utils/dateTimeHelper";

Sentry.init({
  dsn: sentryConfig.dsn,
  environment: sentryConfig.environment,
  // Tracing
  tracesSampleRate: sentryConfig.tracesSampleRate,
  tracePropagationTargets: sentryConfig.tracePropagationTargets,
  // Session Replay
  replaysSessionSampleRate: sentryConfig.replaysSessionSampleRate,
  replaysOnErrorSampleRate: sentryConfig.replaysOnErrorSampleRate,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      // Additional SDK configuration goes in here, for example:
      blockAllMedia: sentryConfig.replay.blockAllMedia,
    }),
    Sentry.feedbackIntegration({
      // Additional SDK configuration goes in here, for example:
      colorScheme: sentryConfig.feedback.colorScheme,
      isNameRequired: sentryConfig.feedback.isNameRequired,
      tags: {
        // Additional tags to be sent with every transaction
        timeZone: getUserTimeZone(),
      },
    }),
  ],
  beforeSend(event) {
    const userTimeZone = getUserTimeZone();

    if (event) {
      event.tags = {
        ...event.tags,
        timeZone: userTimeZone,
      };
    }
    return event;
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    <AppContextProvider>
      <App />
    </AppContextProvider>
  </Router>
);
