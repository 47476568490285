import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { translate } from "../../../constants/propTypes";
import Icon from "@ailibs/feather-react-ts";
import { Link, useNavigate  } from "react-router-dom";

// Import menuDropdown
import NotificationDropdown from "../../commonforboth/topbardropdown/NotificationDropdown";
import ProfileMenu from "../../commonforboth/topbardropdown/ProfileMenu";

// import images
// import logoSm from "../../../assets/images/logo-sm.png";
// // import logoDark from "../../../assets/images/logo-dark.png";
// import logoLight from "../../../assets/images/logo-light.png";

const Header = () => {
  const [isSearch, setSearch] = useState(false);
  const [searchTerm, setSearchTerm] = useState(""); 
  const navigate = useNavigate();

  const toggleLeftmenu = () => {
    var element = document.getElementById("topnav-menu-content");
    if (element) {
      element.classList.toggle("show");
    }
  };

  const onSearchChange =(e) =>{
    setSearchTerm(e.target.value)
  } 

  const handleSubmit = (e) => {
    e.preventDefault(); 
    if (searchTerm.trim()) { 
       navigate(`/search?query=${encodeURIComponent(searchTerm)}`);
    }
  };

  return (
<React.Fragment>
      <div className="navbar-header">
        <div className="d-flex">
          <div className="navbar-brand-box d-flex align-items-center">
            <Link className="" to="/home">
              <h1 className="maphia-text">Maphia</h1>
            </Link>
          </div>

          <button
            type="button"
            className="btn btn-sm px-3 font-size-16 d-none d-md-block d-lg-none header-item"
            onClick={() => {
              toggleLeftmenu();
            }}
          >
            <i className="fa fa-fw fa-bars" />
          </button>
        </div>

        <div className="d-flex flex-grow-1 justify-content-end">
          {/* Desktop Search Input */}
          <div className="dropdown d-inline-block d-none d-md-block col-8">
            <form className="p-3" onSubmit={handleSubmit}>
              <div className="search-box">
                <div className="position-relative">
                  <i className="mdi mdi-magnify search-icon"></i>
                  <input
                    type="text"
                    className="form-control rounded bg-light border-0"
                    placeholder="Search"
                    onChange={onSearchChange}
                  />
                </div>
              </div>
            </form>
          </div>

          {/* Mobile Search Icon */}
          <div className="dropdown d-inline-block d-md-none">
            <button
              type="button"
              className="btn header-item"
              id="page-header-search-dropdown"
              onClick={() => setSearch(!isSearch)}
            >
              <Icon name="search" className="icon-sm" />
            </button>
            <div
              className={
                isSearch
                  ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                  : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
              }
              aria-labelledby="page-header-search-dropdown"
            >
              <form className="p-2" onSubmit={handleSubmit}>
                <div className="search-box">
                  <div className="position-relative">
                    <input
                      type="text"
                      className="form-control rounded bg-light border-0"
                      placeholder="Search"
                      onChange={onSearchChange}
                    />
                    <i className="mdi mdi-magnify search-icon"></i>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <NotificationDropdown />
          <ProfileMenu />
        </div>
      </div>
    </React.Fragment>
  );
};
Header.propTypes = {
  t: translate,
};

export default withTranslation()(Header);
