import { lazy } from "react";

export const TemplateTypes = {
  NONE: "none", //no layout at all
  DEFAULT: "default", //default layout with header and footer
  CONTENT: "content", // they type of layout for regular content
  SPLASH: "splash", // they type of layout for regular content
};

let anonymousRoutes = [
  {
    path: "/",
    component: lazy(() => import("../components/ComingSoon")),
    exact: true,
    template: TemplateTypes.NONE,
  },
  {
    path: "/home",
    component: lazy(() => import("../components/HomeOg")),
    exact: true,
    template: TemplateTypes.DEFAULT,
  },
  {
    path: "/privacy-policy",
    component: lazy(() => import("../components/content/PrivacyPolicy")),
    exact: true,

    template: TemplateTypes.NONE,
  },

  {
    path: "/careers",
    component: lazy(() => import("../components/content/Careers")),
    exact: true,
    template: TemplateTypes.NONE,
  },

  {
    path: "/investors",
    component: lazy(() => import("../components/content/Investors")),
    exact: true,
    template: TemplateTypes.NONE,
  },

  {
    path: "/register",
    component: lazy(() => import("../components/authentication/SignUp")),
    exact: true,
    template: TemplateTypes.NONE,
  },
  {
    path: "/signin",
    component: lazy(() => import("../components/authentication/SignIn")),
    exact: true,
    template: TemplateTypes.NONE,
  },
  {
    path: "/maphia/:name",
    component: lazy(() => import("../components/maphias/MaphiaProfile")),
    exact: true,
    template: TemplateTypes.DEFAULT,
  },
  {
    path: "/search",
    component: lazy(() =>
      import("../components/maphias/searchView/SearchView")
    ),
    exact: true,
    template: TemplateTypes.DEFAULT,
  },
  {
    path: "/explore",
    component: lazy(() => import("../components/common/StarterPage")),
    exact: true,
    template: TemplateTypes.DEFAULT,
  },
  {
    path: "/forgotPassword",
    component: lazy(() =>
      import("../components/authentication/ForgotPassword")
    ),
    exact: true,
  },
  {
    path: "/comingsoon",
    component: lazy(() => import("../components/ComingSoon")),
    exact: true,
    template: TemplateTypes.NONE,
  },
  {
    path: "/cs/:code",
    component: lazy(() => import("../components/ComingSoon")),
    exact: true,
    template: TemplateTypes.NONE,
  },
];

let userRoutes = [
  {
    path: "/invite",
    component: lazy(() => import("../components/common/StarterPage")),
    exact: true,
    template: TemplateTypes.DEFAULT,
  },
  {
    path: "/chat",
    component: lazy(() => import("../components/common/StarterPage")),
    exact: true,
    template: TemplateTypes.DEFAULT,
  },
  {
    path: "/maphias",
    component: lazy(() => import("../components/common/StarterPage")),
    exact: true,
    template: TemplateTypes.DEFAULT,
  },
  {
    path: "/maphias/new",
    component: lazy(() => import("../components/maphias/AddEditMaphia")),
    exact: true,
    template: TemplateTypes.DEFAULT,
  },
  {
    path: "/user/profile",
    component: lazy(() => import("../components/users/Profile")),
    exact: true,
    template: TemplateTypes.DEFAULT,
  },
  {
    path: "/user/profile/:id",
    component: lazy(() => import("../components/users/Profile")),
    exact: true,
    template: TemplateTypes.DEFAULT,
  },
  {
    path: "/user/settings/security",
    component: lazy(() => import("../components/users/Security")),
    exact: true,
    template: TemplateTypes.DEFAULT,
  },
  {
    path: "/user/change-password",
    component: lazy(() =>
      import("../components/authentication/ChangePassword")
    ),
    exact: true,
    template: TemplateTypes.DEFAULT,
  },
];

const routes = {
  anonymous: anonymousRoutes,
  user: [...anonymousRoutes, ...userRoutes],
};

export default routes;
