import "./assets/scss/theme.scss";
import React, { Suspense, useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import debug from "./services/debugService";
import { Route, Routes, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useAppContext } from "contexts/GlobalAppContext";
import routes, { TemplateTypes } from "./routes/routes.js";
import webAnalytics, { WAEventTypes } from "./services/webanalyticsService";
// import VerticalLayout from "./components/layouts/verticallayout";
import HorizontalLayout from "./components/layouts/horizontallayout";
import NonAuthLayout from "./components/layouts/NonAuthLayout";
import NoSideBarLayout from "./components/layouts/verticallayout/NoSideBarLayout";
const _logger = debug.extend("App");
const _wlogger = debug.extend("WebAnalytics");

const App = () => {
  const comingSoonPaths = [
    "/",
    "/comingsoon",
    "/cs/:code",
    "/privacy-policy",
    "/careers",
    "/investors",
  ];
  const { user } = useAppContext();
  const location = useLocation();

  const previousLocationRef = useRef(null);
  const refDomainRef = useRef(null);
  const isFirstPageView = useRef(true);
  const [activeRoutes, setActiveRoutes] = useState(routes.anonymous);

  const mapRoutes = (route, index) => {
    let Component = route.component;
    let WrapperComponent = null;
    let routeComponent = null;
    let bgClass = "";

    if (route.template === TemplateTypes.DEFAULT) {
      WrapperComponent = HorizontalLayout;
    } else if (route.template === TemplateTypes.SPLASH) {
      WrapperComponent = NoSideBarLayout;
    } else {
      WrapperComponent = NonAuthLayout;
      bgClass = "bg-black authentication-bg star-bg";
    }

    if (!comingSoonPaths.includes(route.path)) {
      return (
        <Route key={index} path="*" element={<Navigate to="/comingsoon" />} />
      );
    }

    routeComponent = (
      <Route
        key={index}
        path={route.path}
        exact={route.exact}
        element={
          <Suspense
            fallback={
              <WrapperComponent
                currentUser={user.current}
                handleLogout={user.logout}
              >
                <div className={bgClass}>
                  <div className="container d-flex flex-column justify-content-center align-items-center vh-100">
                    <div
                      role="status"
                      className="spinner-border  text-light m-1 spinner-border loading-spinner"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                </div>
              </WrapperComponent>
            }
          >
            <WrapperComponent
              currentUser={user.current}
              handleLogout={user.logout}
            >
              <Component
                currentUser={user.current}
                handleLogout={user.logout}
              />
            </WrapperComponent>
          </Suspense>
        }
      />
    );
    return routeComponent;
  };

  useEffect(() => {
    _wlogger("App is Initializing web analytics from App");
    webAnalytics.verify();
  }, []);

  useEffect(() => {
    _wlogger("App detected Page changed:", location.pathname);
    webAnalytics.trackPageView(
      WAEventTypes.pageView,
      location,
      previousLocationRef.current,
      refDomainRef.current,
      isFirstPageView.current
    );
    // Update the previous location to the current location after tracking
    previousLocationRef.current = location;
    refDomainRef.current = window.location.hostname;
    isFirstPageView.current = false;
  }, [location, history]);

  useEffect(() => {
    _logger(
      "User logged in status changed. Remapping Routes. isLoggedIn:",
      user.current.isLoggedIn
    );
    if (user.current.isLoggedIn) {
      setActiveRoutes(routes.user);
    } else {
      setActiveRoutes(routes.anonymous);
    }
  }, [user.current.isLoggedIn]);
  return (
    <React.Fragment>
      {/* <Navbar currentUser={user.current} handleLogout={user.logout} /> */}

      <Routes>{activeRoutes.map(mapRoutes)}</Routes>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </React.Fragment>
  );
};

export default App;
