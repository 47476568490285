import axios from "axios";
import debug from "@services/debugService";
import {
  onGlobalError,
  onGlobalSuccess,
  API_HOST_PREFIX,
} from "./serviceHelpers";
const _logger = debug.extend("WebAnalytics");
const api = `${API_HOST_PREFIX}/wa`;

class WebAnalytics {
  constructor() {
    this.utmKeys = [
      "utm_source",
      "utm_medium",
      "utm_campaign",
      "utm_content",
      "utm_term",
    ];
    this.cookieName = "wa";
    this.cookieData = {};
    this.apiPrefix = api;
    this.verifyCompleted = false; // To track if verification is completed
    this.verifyPromise = null; // To store the verify call promise
  }

  verify(payload) {
    _logger("verify web analytics");
    if (!this.verifyPromise) {
      _logger("verify firing");
      this.verifyPromise = new Promise((resolve) => {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
          method: "POST",
          url: `${api}/setup`,
          data: payload,
        };

        axios(config)
          .then((response) => {
            _logger("verify successful:", response.data);
            this.verifyCompleted = true;
            resolve(response.data);
          })
          .catch((error) => {
            _logger("verify Error:", JSON.stringify(error));
            this.verifyCompleted = false;
            // reject(error); //TODO: report this to Admis
          });
      });
    }

    return this.verifyPromise;
  }

  getPageViewData(prevLocation = {}, preDomain = "", isFirstPageView) {
    // populate eventData with these  URL components (current_domain, current_url_path, current_url_protocol, current_url_search),
    const currentLocation = window.location;
    const currentDomain = currentLocation.hostname;
    const currentUrlPath = currentLocation.pathname;
    const currentUrlProtocol = currentLocation.protocol;
    const currentUrlSearch = currentLocation?.search;

    // read the page title
    const currentTitle = this.getPageTitle();

    /* eslint-disable camelcase */
    let pLocation = {
      $referrer: prevLocation.pathname || "",
      $referring_domain: preDomain || "",
    };
    let cookieData = this.getCookieData();

    if (isFirstPageView) {
      if (cookieData.OG_referrerDomain) {
      }
    }

    let pView = {
      current_domain: currentDomain,
      current_url_path: currentUrlPath,
      current_url_protocol: currentUrlProtocol,
      current_url_search: currentUrlSearch,
      current_page_title: currentTitle,

      ...pLocation,
      $current_url: window.location.href,
      ...this.getInitReferrerFromCookie(),
    };
    /* eslint-disable camelcase */
    _logger("PageViewData:", JSON.stringify(pView));
    return pView;
  }

  cleanLocation(location = {}) {
    //delete the hash and key and state from the location object

    let cleanLocation = { ...location };
    delete cleanLocation.hash;
    delete cleanLocation.key;
    delete cleanLocation.state;
    return cleanLocation;
  }

  getPageTitle() {
    let title = document.title;

    if (title === "Maphia - Unlock the Power of Trusted Connections") {
      title = "Splash";
    } else if (title === "Register as a Maphia User") {
      title = "Register";
    } else if (title.startsWith("Search Results for")) {
      // Remove - Maphia from string
      title = title.replace(/\s*-\s*Maphia$/, "").trim(); //
      if (title.length > "Search Results for".length) {
        //turn Search Results for paypal to "Search Results paypal"
        title = title.replace(/(Results) for /, "$1 ");
      } else {
        //no search query specified
        title = "Search";
      }
    } else {
      // Remove "Maphia -" from the start
      title = title.replace(/^Maphia\s*-\s*/, "");

      // Remove "- Maphia" from the end
      title = title.replace(/\s*-\s*Maphia$/, "");

      //remove the occurance of Maphia at the end of the title with "in" or "at"  or "with" or "to" preceeding it
      title = title.replace(/\s+(in|at|with|to)\s+Maphia$/, "");

      //remove Maphia at the end of the title
      title = title.replace(/\s+Maphia$/, "");
    }
    title = title.trim();
    // Update the document title
    return title;
  }

  //   The standard page view event ($mp_web_page_view)
  //  includes the page title (current_page_title),
  //  URL components (current_domain, current_url_path, current_url_protocol, current_url_search),
  //  and marketing parameters described below. Additional page view event properties can also be added as event properties.

  // Track event function, ensuring init is completed
  async trackPageView(
    eventName,
    currentLocation,
    previousLocation = {},
    preDomain = "",
    isFirstPageView,
    additionalProperties = {}
  ) {
    _logger(
      "track",
      JSON.stringify({ eventName, currentLocation, previousLocation })
    );
    if (!this.verifyCompleted) {
      await this.verifyPromise; // Wait for init to complete
    }

    currentLocation = this.cleanLocation(currentLocation);
    previousLocation = this.cleanLocation(previousLocation);

    let dataForThisView = { ...currentLocation };
    //time for helmet to update

    _logger("waiting for page view:", { eventName, previousLocation });
    await new Promise((resolve) => setTimeout(resolve, 200));

    let pvData = this.getPageViewData(
      previousLocation,
      preDomain,
      isFirstPageView
    );
    dataForThisView = {
      ...dataForThisView,
      ...pvData,
      ...additionalProperties,
    };

    _logger("track event proceeding:", eventName, dataForThisView);

    const eventPayload = {
      eventName: eventName,

      pageViewData: dataForThisView,
      //   ...this.cookieData, // Include the cookie data in the tracking event
    };

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      url: `${api}/track`,
      data: eventPayload,
    };

    // Make the API call for tracking event
    return axios(config)
      .then((response) => {
        _logger("Event tracked:", eventName);
        return response;
      })
      .then(onGlobalSuccess)
      .catch(onGlobalError);
  }

  // Helper function to get the value of a cookie
  getCookie(name) {
    const cookieArr = document.cookie.split("; ");
    for (let cookie of cookieArr) {
      const cookiePair = cookie.split("=");
      if (name === cookiePair[0]) {
        return decodeURIComponent(cookiePair[1]);
      }
    }
    return null;
  }

  getCookieAsObject(name) {
    const cd = this.getCookie(name);
    if (cd) {
      return JSON.parse(cd);
    }
    return null;
  }

  getInitReferrerFromCookie() {
    const cd = this.getCookieAsObject(this.cookieName);
    let results = {
      $initial_referrer: "$direct",
      $initial_referring_domain: "$direct",
      $search_engine: "",
    };

    if (cd) {
      if (cd["$initial_referrer"]) {
        results["$initial_referrer"] = cd["$initial_referrer"];
      }

      if (cd["$initial_referring_domain"]) {
        results["$initial_referring_domain"] = cd["$initial_referring_domain"];
      }

      if (cd["$search_engine"]) {
        results["$search_engine"] = cd["$search_engine"];
      }
    }

    _logger("Initial Referrer from Cookie:", results, cd);
    return results;
  }

  setCookie(name, value, days = 1000) {
    // Calculate Max-Age in seconds
    const maxAge = days * 24 * 60 * 60;

    // Get the top-level domain
    const domain = window.location.hostname.split(".").slice(-2).join(".");

    const stringValue =
      typeof value === "object" ? JSON.stringify(value) : String(value);
    const encodedValue = encodeURIComponent(stringValue);

    // Set the cookie
    document.cookie = `${encodeURIComponent(
      name
    )}=${encodedValue}; max-age=${maxAge}; path=/; domain=.${domain}; SameSite=Lax`;
  }

  getUtmParams() {
    const params = new URLSearchParams(window.location.search);
    let utmParams = {};
    this.utmKeys.forEach((key) => {
      const value = params.get(key);
      if (value) {
        utmParams[key] = value;
      }
    });
    return utmParams;
  }

  // Get referrer and referrer domain if external
  getUserReferrer() {
    const referrer = document.referrer;
    const referrerDomain = referrer ? new URL(referrer).hostname : "";
    const currentDomain = window.location.hostname;

    //from mixpanel
    // '$initial_referrer': this['props']['$initial_referrer'],
    // '$initial_referring_domain': this['props']['$initial_referring_domain']

    if (referrer && referrerDomain !== currentDomain) {
      return {
        referrer: referrer,
        referrerDomain: referrerDomain,
      };
    }
    return {};
  }

  getReferringSearch(referrer) {
    if (referrer?.search("https?://(.*)google.([^/?]*)") === 0) {
      return "google";
    } else if (referrer?.search("https?://(.*)bing.com") === 0) {
      return "bing";
    } else if (referrer?.search("https?://(.*)yahoo.com") === 0) {
      return "yahoo";
    } else if (referrer?.search("https?://(.*)duckduckgo.com") === 0) {
      return "duckduckgo";
    } else if (referrer?.search("https?://(.*)fake.site") === 0) {
      return "fake.site";
    } else {
      return null;
    }
  }
  getInitialReferrer(referrerData) {
    let initialRef = {
      $initial_referrer: referrerData.referrer ?? "$direct",
      $initial_referring_domain: referrerData.referrerDomain ?? "$direct",
      $search_engine: this.getReferringSearch(referrerData.referrer) ?? null,
    };

    // let initialRef = {
    //   $initial_referrer: "https://duckduckgo.com" ?? referrerData, // "$direct",
    //   $initial_referring_domain: "duckduckgo.com", //"$direct",
    // };

    return initialRef;
  }

  getViewReferrer(currentLocation, previousLocation) {
    const currentPath = currentLocation.pathname;
    const previousPath = previousLocation.pathname;

    return {
      currentPath,
      previousPath,
    };
  }

  // Initialize the cookie
  init() {
    const cookieObj = this.getCookieAsObject(this.cookieName);
    const utmParams = this.getUtmParams();
    const referrerInfo = this.getUserReferrer();
    let cookieData = {};

    if (cookieObj) {
      _logger("Existing cookie found:", cookieObj);

      // Check if any UTM params already exist, move all to OG_ if one exists
      const hasExistingUtm = this.utmKeys.some((key) => cookieObj[key]);
      if (hasExistingUtm && Object.keys(utmParams).length > 0) {
        this.utmKeys.forEach((key) => {
          if (cookieObj[key]) {
            cookieData[`OG_${key}`] = cookieObj[key];
          }
        });
      }

      // Merge new UTM values into cookie data know we preserved existing values above
      cookieData = { ...cookieObj, ...cookieData, ...utmParams };

      // If referrer and referrerDomain exist, add OG_ prefix for existing values
      if (cookieObj.referrer && referrerInfo.referrer) {
        cookieData["OG_referrer"] = cookieObj.referrer;
        cookieData["OG_referrerDomain"] = cookieObj.referrerDomain;
      }

      // Merge new referrer info
      cookieData = { ...cookieData, ...referrerInfo };
      cookieData.isNew = false;
    } else {
      // No existing cookie, set new values
      cookieData = {
        ...utmParams,
        ...referrerInfo,
        ...this.getInitialReferrer(referrerInfo),
      };
      cookieData.isNew = true;

      _logger("Setting new Cookie:", cookieData);
    }

    // Set the cookie
    this.setCookie(this.cookieName, JSON.stringify(cookieData), 30);
    this.cookieData = cookieData;
  }

  // Expose cookieData
  getCookieData() {
    return this.cookieData;
  }
}

// Usage example:
const webAnalytics = new WebAnalytics();
webAnalytics.init();

export const WAEventTypes = {
  pageView: "PageView",
  vouch: "Vouch",
  singUp: "Sign Up",
  invite: "Invite",
  maphiaJoin: "MaphiaJoin",
  maphiaCreate: "MaphiaCreate",
};

export default webAnalytics;
